import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { gql, useQuery } from '@apollo/client';
import { CrudDataGrid, IDataGridField } from './permissionsEditor';
import { IUpsertFieldSchema } from './crud/upsertIemModal';


export const GET_APP_DATA = gql`
  query GetAppData($appId: ID!) {
    getAppData(id: $appId) {
      id
      name
      apiTokens {
        id
        token
      }
    }
  }
`;


const apiTokensFields: IDataGridField[] = [
  { fieldName:"id", fieldText: "ID"},
  { fieldName:"token", fieldText: "Token"},
  // { fieldName:"authType", fieldText: "Authorization Type"},
  // { fieldName:"crudRuleString", fieldText: "CRUD Rule"},
  // { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];
export const apiTokensUpsertSchema: IUpsertFieldSchema[] = [
  { fieldName:"id", fieldText: "ID", required: true},
  { fieldName:"token", fieldText: "Token"},
  // { fieldName:"crudRuleString", fieldText: "CRUD Rule", required: true},
  // { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AppSettings({appId}: any) {
  const {data, loading, error} = useQuery(GET_APP_DATA, {variables:{appId}});

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  if(loading) return "Loading...";
  if(error) return "Error...";

  if(data)
    console.log("got tokens:", data, data.getAppData.apiTokens);

  const useApiTokens = data.getAppData?.apiTokens;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>API Tokens</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CrudDataGrid fields={apiTokensFields} rowData={useApiTokens} />

          {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Collapsible Group Item #2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Collapsible Group Item #3</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}
