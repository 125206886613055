import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ListItem, TextField, List } from '@mui/material';
import { IDataField } from '../permissionsEditor';
import { useEffect } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface IUpsertDataField extends IDataField {
  // field: string
  //headerName: string
  // componentFn?: IDataGridFieldComponentFunction
  // inputProps?: any
}

export interface IUpsertFieldSchema extends IDataField {
  // fieldName: string
  // fieldText?: string
  required?: boolean
  type?: string
  // inputProps?: any
  readonly?: boolean
  placeholder?: string
  validators?: ((text: string) => string)[]
  hidden?: boolean
}

export interface IUpsertItemModalProps<T> {
  open: boolean
  setOpen: (isOpen: boolean) => void
  entityName: String
  data: T
  schema: IUpsertFieldSchema[]
  upsertFunction: (newData: T) => Promise<void>
}

export default function UpsertItemModal({
    open,
    setOpen,
    entityName,
    data,
    schema,
    upsertFunction,
  }: IUpsertItemModalProps<any>) {
  const [newData, setNewData] = React.useState({...data});


  useEffect(()=>{
    console.log("ran use effect")
    setNewData({...data});
  }, [data]);


  //TODO: add validators...

  console.log("upsert rerender", newData, data);

  const handleSave = async() => {
    try{
      const upsertResult = await upsertFunction(newData);
      console.log("upsertResult", upsertResult);
    } catch(e){
      console.error(e);
    } 
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          { entityName }
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          { data.id ? "Update " : "Create New " }{ entityName }
        </Typography>
        <List>
        {
          schema.map(s => 
            <ListItem>
              {
                (s.componentFn && s.componentFn({ 
                  ...s.inputProps,
                  data: { value: newData[s.fieldName]}, //TODO: conform to Interface 
                  field: s,
                  onInput: (val) => {
                    //validate?
                    newData[s.fieldName] = val;
                    setNewData({...newData});
                  }
                })) ||
                <TextField
                  required={s.required == true}
                  id="outlined-required"
                  label={s.fieldText || s.fieldName}
                  defaultValue={ newData[s.fieldName] || "" }
                  placeholder={ s.placeholder || "" }
                  onInput={(e) => {
                    //validate?
                    newData[s.fieldName] = e.target.value;
                    setNewData({...newData});
                  }}
                />

              }


            </ListItem>
          )
        }
        </List>
        <Button variant="contained"
          onClick={handleSave}
        >Save changes</Button>
      </Box>
    </Modal>
  );
}
