import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import styled from '@emotion/styled';
import { Button, Card, CardActions, CardContent, CardMedia, Collapse, SvgIcon } from '@mui/material';
import AppConsole, { GET_APP_DATA } from './AppConsole';
import { useQuery } from '@apollo/client';
import PanoramaIcon from '@mui/icons-material/Panorama';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import TerminalIcon from '@mui/icons-material/Terminal';
import DataObjectIcon from '@mui/icons-material/DataObject';
import SettingsIcon from '@mui/icons-material/Settings';
import DataObjectDataTable from '../components/dataObjectDataTable';
import DataObjectDataGrid from '../components/dataObjectDataGrid';

import ListAltIcon from '@mui/icons-material/ListAlt'; //data?
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faHome, faTerminal, faGear, faPlus, faUsersGear } from '@fortawesome/free-solid-svg-icons'
import SchemaEditor from '../components/schemaEditor';
import PermissionsDataGrid from '../components/permissionsEditor';
import AppSettings from '../components/appSettings';

const MainColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  background: #eee;
`;

const ListContentsContainer = styled.div`
  min-width: 210px;
  
`;
const MainContentsContainer = styled(Card)`
  background: white;
  width: 100%;
  padding: 20px;
  // height: 100%;
  // display: flex;
`;

export const PlaceholderImage = styled(PanoramaIcon)`
  // width: 100%;
  height: 150px;
  // max-height: 250px;
  width: 150px;
`;

export const ListItemButtonCustom = styled(ListItemButton)`
  padding: 2px 10px;
  background: #eee;
  border-bottom: 1px solid #ddd;
  & >*>span {
    font-size: 11pt;
  }
  // background: linear-gradient(0deg, #ccc, #eee 20%, #eee 80%, #fff 90%, #ddd 100%)
  // & > * {
  //   padding: 2px 10px;
  // }

`;


export const ListItemIconCustom = styled(ListItemIcon)`
  font-size: 16px;
  min-width: 35px;
  & > svg{
    font-size: 16px !Important;
    width: 1em;
  }
`;

interface IListIndentedProps {
  indent: number
}

export const ListIndented = styled<IListIndentedProps>(List)`
  ${props => props.indent && 'padding-left: ' +10+'px;'}
  background: #ccc;
`;


const FontAwesomeSvgIcon = styled(FontAwesomeIcon)`
  // font-size: 16px !Important;
  width: 1em;
`;


const menuItems = [
  { 
    name: "Overview",
    // icon: <HomeIcon/>,
    icon: <FontAwesomeSvgIcon icon={faHome} size="20px"/>,
  },
  { 
    name: "Collections",
    icon: <FontAwesomeSvgIcon icon={faDatabase} size="20px"/>,
  },
  // { 
  //   name: "Data",
  //   // icon: LibraryBooksIcon,
  //   icon: <FontAwesomeIcon icon="fa-regular fa-table-list" />,
  // },
  // { 
  //   name: "Attributes",
  //   // icon: DataObjectIcon,
  //   icon: <FontAwesomeIcon icon="fa-regular fa-table-tree" />,
  // },
  { 
    name: "Permissions",
    // icon: <SettingsIcon/>,
    icon: <FontAwesomeSvgIcon icon={faUsersGear} size="20px"/>,
  },
  { 
    name: "Settings",
    // icon: <SettingsIcon/>,
    icon: <FontAwesomeSvgIcon icon={faGear} size="20px"/>,
  },
  { 
    name: "Debugger",
    icon:  <TerminalIcon/>
    // icon: <FontAwesomeSvgIcon icon="fa-rectangle-terminal" size="20px"/>,
    // icon: <FontAwesomeSvgIcon icon={faTerminal} size="20px"/>,
    // icons: () => <TerminalIcon/>/>,
  },
];

export default function AppView({ appId }: any) {
  const { loading, error, data, refetch } 
    = useQuery(GET_APP_DATA, {variables:{ appId }});
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [schemaSelected, setSchemaSelected] = React.useState(null);

  if (loading) return <p>Loading...</p>;
  if (error) {
    error && console.log("error", error);
    return <>
      <p>Error loading schemas :( </p>
    </>;
  }

  const currentMenuItem = menuItems[selectedIndex];

  const useAppData = data?.getAppData;
  console.log("useAppData?", useAppData);

  let contents;

 

  let selectedDataObjects = schemaSelected?.data?.schema  
    && useAppData.dataObjects.filter(dO => dO.schema.id == schemaSelected.data.id)
    .map(dO => ({id: dO.id, ...JSON.parse(dO.data)}));
  console.log("schemaSelected?", schemaSelected, selectedDataObjects);


  switch(currentMenuItem?.name){
    case "Collections":
      contents = <>
          {/* <h4>{schemaSelected?.data?.name}</h4>  */}
          <Typography variant='h4'>{schemaSelected?.data?.name}</Typography>
          <hr/>
          <p/>
          {
            schemaSelected?.mode == "dataObjects" && schemaSelected?.data?.schema &&
            <div>
              <Typography variant='h6'>Data</Typography>
              {/* <DataObjectDataTable
                //TODO: need to deprecate parsing JSON
                schema={JSON.parse(schemaSelected.data.schema)}
                dataObjects={selectedDataObjects}
              /> */}
              <DataObjectDataGrid
                //TODO: need to deprecate parsing JSON
                schema={JSON.parse(schemaSelected.data.schema)}
                dataObjects={selectedDataObjects}
                schemaId={schemaSelected.data.id}
              />
            </div>
          }
          {
            schemaSelected?.mode == "schema" && schemaSelected?.data?.schema &&
            <div>
              <Typography variant='h6'>Fields</Typography>
              <p/>
              <SchemaEditor
                schema={JSON.parse(schemaSelected.data.schema)}
              />
            </div>
          }
        </>;
      break;
    case "Debugger":
      contents = <AppConsole appId={appId}/>;
      break;
    case "Permissions":
      contents = useAppData?.authEntries
        &&
        useAppData.authEntries.length > 0 ?
        <PermissionsDataGrid 
          authEntries={useAppData.authEntries}
          appId={appId}
        />
        : <span>Unauthorized</span>;
      break;
    case "Settings":
      contents = <AppSettings appId={appId}/>;
      break;
  }
  

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index == selectedIndex ? -1 : index);
  };

  const secondary = false;
  return (
    <MainColumnLayout>
      <ListContentsContainer>
        <List dense={false}>
          { menuItems.map((mi, i) =>
          {
            const Icon = mi.icon;
            return <>
              <ListItemButtonCustom
                key={i}
                selected={selectedIndex === i}
                onClick={(event) => handleListItemClick(event, i)}
              >
                {/* <ListItem> */}
                  <ListItemIconCustom>
                    {/* <Icon/> */}
                    {  mi.icon }
                    {/* <FolderIcon /> */}
                  </ListItemIconCustom>
                  <ListItemText
                    primary={mi.name}
                    secondary={secondary ? 'Secondary text' : null}
                  />
                {/* </ListItem> */}
              </ListItemButtonCustom>
              {
                mi.name == "Collections" &&
                <Collapse in={selectedIndex === i} timeout="auto" unmountOnExit>
                  <ListIndented indent={10} component="div" disablePadding>
                    {
                      useAppData?.schemas?.map(s =>  
                        <ListItemButtonCustom sx={{ pl: 4 }}>
                          <ListItemText primary={s.name} 
                            onClick={()=>setSchemaSelected({mode:"dataObjects", data:s})}
                          />
                          <ListItemIconCustom style={{float:"right"}}
                            onClick={()=>setSchemaSelected({mode:"schema", data:s})}
                          >
                            <FontAwesomeSvgIcon
                              icon={faGear} 
                            />
                          </ListItemIconCustom>
                        </ListItemButtonCustom>
                      )
                    }
                    <ListItemButtonCustom sx={{ pl: 4 }}>
                      <ListItemIconCustom style={{float:"right"}}
                        //onClick={()=>setSchemaSelected({mode:"schema", data:s})}
                      >
                        <FontAwesomeSvgIcon
                          icon={faPlus} 
                        />
                      </ListItemIconCustom>
                      <ListItemText primary="Add new collection" 
                        //onClick={()=>setSchemaSelected({mode:"dataObjects", data:s})}
                      />
                      {/* <ListItemIconCustom style={{float:"right"}}
                        onClick={()=>setSchemaSelected({mode:"schema", data:s})}
                      >
                        <FontAwesomeSvgIcon
                          icon={faGear} 
                          size="20px"
                        />
                      </ListItemIconCustom> */}
                    </ListItemButtonCustom>
                  </ListIndented>
                </Collapse>
              }
              {
                mi.name == "Debugger" &&
                <Collapse in={selectedIndex === i} timeout="auto" unmountOnExit>
                  <ListIndented indent={10} component="div" disablePadding>
                    {
                      useAppData?.schemas?.map(s =>  
                        <ListItemButtonCustom sx={{ pl: 4 }}
                          
                        >
                          <ListItemText primary={s.name} 
                            onClick={()=>setSchemaSelected({mode:"debug:dataObjects", data:s})}
                          />
                          <ListItemIconCustom style={{float:"right"}}
                            onClick={()=>setSchemaSelected({mode:"debug:schema", data:s})}
                          >
                            <FontAwesomeSvgIcon
                              icon={faGear} 
                              size="20px"
                            />
                          </ListItemIconCustom>
                        </ListItemButtonCustom>
                      )
                    }
                  </ListIndented>
                </Collapse>
              }
            </>
          }
        )}
        </List>
      </ListContentsContainer>
      <MainContentsContainer elevation="5">
        {/* <CardContent> */}
          {/* <Typography gutterBottom variant="h4" component="div">
            {currentMenuItem?.name}
          </Typography> */}
          {/* <Typography gutterBottom variant="h5" component="div">
            {useAppData.name}
          </Typography> */}
        {/* </CardContent> */}

        { contents}
        {/* <CardMedia
          // component="div"
          height="140px"
          // image="/static/images/cards/contemplative-reptile.jpg"
          // alt="green iguana"
        >
          <PlaceholderImage/>
        </CardMedia>
        <CardContent>
           <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography> 
        </CardContent>
        */}
      </MainContentsContainer>
    </MainColumnLayout>
  );


}
