import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useUserContext, useUserState } from '../providers/UserProvider';




const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    login(input:{email:$email, password:$password}){
      user {
        id
        email
      }
      token
    }
  }
`;



// const LOGOUT = gql`
//   mutation Logout {
//     logout
//   }
// `;

const USER_DATA = gql`
  query UserData {
    myData{
      user {
        id
        email
      }
    }
  }
`;

interface User{
  id: number
  email: string
}

interface IUserData {
  user: User
  token: string
}

interface IUserDataResonse {
  userData: IUserData
}


// // const CREATE_BIKE = gql`
// //   mutation Login($email: String!, $password: String!) {
// //     login(email:$email, password:$password) {
// //       id
// //       email
// //       first_name
// //       last_name
// //       role
// //     }
// //   }
// // `;
// // interface ILoginModalProps  extends IModalProps{

// // }

export function LoginModal2({open, setOpen}: ILoginModalProps){
    const userContext = useUserContext();


    React.useEffect(()=>{

    })
    

    // function useUserState(){
    //   return userContext.getUseUserState()();
    // }
    const userState = useUserState();
    // const userState = userContext.getUseUserState();
    // const userState = {};//useUserState();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    //const [isLoggedIn, setLoggedIn] = useState(false);

    

    const [loginQuery] = useLazyQuery(LOGIN);
    // const [logoutMutation] = useMutation(LOGOUT);
    const { data, loading, error } = useQuery(USER_DATA);

    console.log("got UserContext data", userContext.userData);

    const isLoggedIn = userContext?.userData?.user?.id != null;

    if (loading) return <p>Loading...</p>;
    if (error){
      console.log("not logged in");// TODO: verify
    } //return <p>Error :( {console.log("user error", error)}</p>;

    if(!isLoggedIn && data?.useData != null){//should be logged in
      //setLoggedIn(true);
      console.log("setting user data", data?.userData);
      //data?.useData && userContext.setUserData(data.userData);
    }

    //const userIsLoggedIn = data && data.me != null;

    console.log("got user data...", data);

    const onEmailInput = (e) => {
        const val = e.target.value;
        setEmail(val);
    };
    const onPasswordInput = (e) => {
        const val = e.target.value;
        setPassword(val);
    };
    const logout = async() => {
      console.error("not implemented!");

      // try{
      //   const logoutResult: any = await logoutMutation();
      //   console.log("logoutResult", logoutResult);

      //   //const token = logoutResult?.data?.login;
      //   // remove token
      //   localStorage.setItem('token', '');
      //   setLoggedIn(false);
      // }catch(e){
      //   console.log("logout failed", e);
      // }
    };
    const login = async() => {
      try{
        const loginResult: any = await loginQuery({variables:{email, password}});
        console.log("loginResult", loginResult);

        const token = loginResult?.data?.login?.token;
        // got token
        localStorage.setItem('token', token);
        console.log("token", token);

        // //get userData
        // const userDataResult: any = await loginMutation({variables:{email, password}});


        // const userData: IUserData = {
        //   email.
        // };
        loginResult?.data?.login && userContext.setUserData(loginResult?.data?.login);

        //setLoggedIn(true);
      }catch(e){
        console.log("login failed", e);
        //setLoggedIn(false);
      }
    };

    console.log("rerender (userState):", userState, isLoggedIn);

    return (
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>user state?:{userState?.user?.id}</div>
          {
            !isLoggedIn ? <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Login
            </Typography>
            <FieldsLayout>
              <TextField
                required
                id="outlined-required"
                label="Email"
                name="email"
                onInput={onEmailInput}
                // autoComplete="current-email"
              />
              <TextField
                required
                id="outlined-required"
                label="Password"
                type="password"
                autoComplete="current-password"
                onInput={onPasswordInput}
              />
              <Button
                variant='contained'
                disabled={isLoggedIn}
                onClick={()=>login()}
              >Login</Button>
            </FieldsLayout>
            </>
            :<>
              <>
                <div>Logged In</div>
                <button disabled={!isLoggedIn} onClick={()=>logout()}>Logout</button>
              </>
            </>
          }

          {/* isLoggedIn ? 
            <>
              <div>Logged In</div>
              <button disabled={!isLoggedIn} onClick={()=>logout()}>Logout</button>
            </>
            :
            <table>
                <tr><td>Email:</td><td><input onInput={onEmailInput}/></td></tr>
                <tr><td>Password:</td><td><input type="password" onInput={onPasswordInput}/></td></tr>
                <tr><td colSpan={2}><button disabled={isLoggedIn} onClick={()=>login()}>Login</button></td></tr>
            </table> */}
        </Box>
      </Modal>
    );
}





const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ILoginModalProps {
    open: boolean
    setOpen: (isOpen: boolean) => void
}

const FieldsLayout = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    height: 60px;
  }
`;

export function LoginModal({ open, setOpen }: ILoginModalProps) {
  //const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);



  return (
    <div>
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Login
          </Typography>
          <FieldsLayout>
            <TextField
              required
              id="outlined-required"
              label="Email"
              name="email"
              // autoComplete="current-email"
            />
            <TextField
              required
              id="outlined-required"
              label="Password"
              type="password"
              autoComplete="current-password"
            />
            <Button variant='contained'>Login</Button>
          </FieldsLayout>
        </Box>
      </Modal>
    </div>
  );
}


export default LoginModal2;