//Use soft relations for now

interface ICrudRule {
  C: boolean
  R: boolean
  U: boolean
  D: boolean
}

const defaultCrud = {
  C: false,
  R: false,
  U: false,
  D: false,
};

const crudChars = ["c", "r", "u", "d"];
function parseCrudRule(crudRule: string): ICrudRule{
  const crudResult = crudChars.reduce((r,c) => {
    //if(crudRule.indexOf(c) != -1){
    r[c.toUpperCase()] = crudRule.indexOf(c) != -1;
    //}
    return r;
  }, {});
  return crudResult as ICrudRule;
}


// @Entity()
export class AuthMatrixEntry<T> {
    // @PrimaryGeneratedColumn()
    id: number;
  
    userId: number;

    targetId: number;
    targetTypeName: string;
    target: T;

    crudRule: ICrudRule = {
      C: false,
      R: false,
      U: false,
      D: false,
    };

    constructor(target: T, targetId: number, crudRule: string){
      this.targetTypeName = target.constructor.name;
      this.target = target;
      this.targetId = targetId;
      this.crudRule = parseCrudRule(crudRule);

      console.log("this.lookup", AuthMatrixEntry.lookup);

      if(AuthMatrixEntry.lookup[`${this.targetTypeName}:${this.targetId}`]){
        console.error("auth rule already defined");
      }else{
        AuthMatrixEntry.lookup[`${this.targetTypeName}:${this.targetId}`] = this;
      }
      
    }

    static getAuthRule(target: any, targetId: number){
      const typeName = target.constructor.name;
      const authAntry = AuthMatrixEntry.lookup[`${typeName}:${targetId}`];
      if(authAntry){
        return authAntry.crudRule;
      }else{
        return {
          C: false,
          R: false,
          U: false,
          D: false,
        };
      }
    }

    static lookup = {};
    //aux
    // writeFlag = false;

}
