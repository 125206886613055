import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useState } from "react";
import ERDDiagram from "../components/erdDiagram";
import LoginModal from "../components/loginModal";
import MovableDividerContaier from "../components/movableDividerContainer";
import TextEditor from "../components/textEditor";
import ERDDiagramPlayground from "./ERDiagramPlayground";
import { useUserState } from "../providers/UserProvider";
import { CQueryParser } from "../utils/CQuery";


const MainLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  background: #eee;
`;

const HeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  // height: 100%;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  // background: #eee;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  // background: #eee;
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Divider = styled.div`
  height: 4px;
  width: 100%;
  background: grey;
  border: 2px outset;
  cursor: ns-resize;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
`;
const ColumnSpacer = styled.div`
  flex-grow: 1;
`;


const GET_USER_DATA = gql`
  query GetUserData {
    myData {
      apps {
        id
        name
        authEntries{
          id
          userId
          crudRuleString
          permissionsCrudRuleString
          authType
        }
        schemas {
          id
          name
          schema
        }
        dataObjects {
          id
          schemaId
          data
          schema {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_APP_DATA = gql`
  query GetAppData($appId: ID!) {
    getAppData(id: $appId) {
      id
      name
      authEntries{
        id
        userId
        crudRuleString
        permissionsCrudRuleString
        authType
        attributes {
          id
          fieldName
          crudRuleString
          permissionsCrudRuleString
          authType
          userId
        }
      }
      schemas {
        id
        name
        schema
      }
      dataObjects {
        id
        schemaId
        data
        schema {
          id
          name
        }
      }
    }
  }
`;

const GET_SCHEMAS = gql`
  query GetSchemas {
    schemas {
      id
      name
      schema
    }
    
  }
`;

const GET_DATA_OBJECTS = gql`
  query GetDataObjects {
    dataObjects {
      id
      data
      schemaId
      schema {
        id
        name
        schema
      }
    }
    
  }
`;

const UPDATE_SCHEMA = gql`
  mutation UpdateSchema($id: ID!, $input: UpdateSchemaInput!){
    updateSchema(id:$id, input: $input) {
      id
    }
  }
`;
const ADD_SCHEMA = gql`
  mutation AddSchema($input: AddSchemaInput!){
    addSchema(input: $input) {
      id
    }
  }
`;
export const UPDATE_DATA_OBJECT = gql`
  mutation UpdateDataObject($id: ID!, $input: UpdateDataObjectInput!){
    updateDataObject(id:$id, input: $input) {
      id
    }
  }
`;
export const ADD_DATA_OBJECT = gql`
  mutation AddDataObject($input: AddDataObjectInput!){
    addDataObject(input: $input) {
      id
    }
  }
`;

// const ADD_DATA_OBJECT = gql`
//   mutation AddDataObject($input: AddDataObjectInput!){
//     addDataObject(input: $input) {
//       id
//     }
//   }
// `;


type IObjectVisitFn<T> = (key: string, data: T) => void;
type ILookup<T> = { 
  [name: string]: T
};

function iterateObject<T>(obj: ILookup<T>, onVisit: IObjectVisitFn<T>){
  Object.keys(obj).map((key) => {
      if(key == '__ref') return; // TODO: fix
      const data = obj[key];
      onVisit(key, data);
  });
}


const testQuery = `{
  "Books": {
    "title": {},
    "author": {
      "name": {}
    }
  }
}`;

interface ISchemaData {
  id: string
  name: string
  schema: string
}
interface IDataObjectData {
  id: string
  data: string
  schema?: ISchemaData
}

class Schema {
  schemaData: ISchemaData;
  schema: ILookup<string>;

  constructor(schemaData: ISchemaData){
    this.schemaData = schemaData;
    this.schema = JSON.parse(schemaData.schema);
  }
}

class DataObject {
  objectData: IDataObjectData;
  data: ILookup<string>;

  constructor(schemaData: IDataObjectData){
    this.objectData = schemaData;
    this.data = JSON.parse(schemaData.data);
  }
}

interface IParsedDataOnFieldData{
  fieldName: string
  isRequired: boolean
  dataType: string
  hasField: boolean
  data: Object
  referencedSchemaMeta: Object
  referencedSchema: Object
  referencedData: Object
  referencedDataMeta: Object
}

// function CQueryParseJSON(jsObjectText: string){
//   const recursiveFn = new Function(`return ${jsObjectText};`);
//   return recursiveFn();
// }

const StateTest = () => {
  const userState = useUserState();
  
  console.log("--> rerender stateTestButton", userState);
  return (
    <div>stateTest:{userState?.email}</div>
  );
};

interface IAppConsoleProps {
  appId: string
}

const AppConsole = ({ appId }: IAppConsoleProps) => {


  const { loading:aLoading, error:aError, data:aData, refetch:aRefetch } 
    = useQuery(GET_APP_DATA, {variables:{ appId }});
  // const { loading:uLoading, error:uError, data:uData, refetch:uRefetch } = useQuery(GET_USER_DATA);
  const { loading, error, data, refetch } = useQuery(GET_SCHEMAS);
  const { 
    loading:dLoading, error: dError, data: dData, refetch: dRefetch 
  } = useQuery(GET_DATA_OBJECTS);
  const [ updateSchemaMutation, { 
    data: sData, loading: sLoading, error: sError 
  }] = useMutation(UPDATE_SCHEMA);
  const [ addSchemaMutation ] = useMutation(ADD_SCHEMA);
  const [ updateDataObjectMutation, { 
    data: doData, loading: doLoading, error: doError 
  }] = useMutation(UPDATE_DATA_OBJECT);
  const [ addDataObjectMutation ] = useMutation(ADD_DATA_OBJECT);

  const [ selection, setSelection ] = useState(null);
  const [ query, setQuery ] = useState({data: testQuery});
  //const [ schemaSelected, setSchemaSelected ] = useState(null);
  // const [ dataObjectSelected, setDataObjectSelected ] = useState(null);
  const [ editorText, setEditorTextAux ] = useState(null);
  const [ editorText2, setEditorText2 ] = useState(null);
  const [ infoText, setInfoText ] = useState("");
  const [ loginOpen, setLoginOpen ] = useState(false);

  //
  console.log("got app id", appId);


  if (loading || dLoading) return <p>Loading...</p>;
  if (error || dError || aError) {
    error && console.log("error", error);
    dError && console.log("dError", dError);
    aError && console.log("aError", aError);
    return <>
      <p>Error loading schemas :( </p>
    </>;
  }

  //

  let useSchemas;
  let useDataObjects;

  console.log("--got app data:", aData);

  if(aData?.getAppData){
    //TODO: select app...
    useSchemas = aData.getAppData?.schemas;
    useDataObjects = aData.getAppData?.dataObjects;
  } else {//not logged in
    // useSchemas = data?.schemas;
    // useDataObjects = dData?.dataObjects;
  }



  const schemaLookup = useSchemas?.reduce((r, c)=>{
    r[c.name] = c;
    return r;
  }, {});
  const dataObjectLookup = useDataObjects?.reduce((r, c)=>{
    r[c.id] = c;
    return r;
  }, {});
  console.log("schema lookup", schemaLookup);
  console.log("dataObject lookup", dataObjectLookup);

  const setSchemaSelected = (schema) => {
    setSelection({
      type: "schema",
      data: schema,
    });
    setEditorText(schema.schema);
  };
  const setDataObjectSelected = (dataObject) => {
    setSelection({
      type: "dataObject",
      data: dataObject,
    });
    setEditorText(dataObject.data);
  };

  const setQuerySelected = (queryArg) => {
    setSelection({
      type: "query",
      data: queryArg,
    });
    setEditorText(queryArg.data);
  };

  const runQueryClick = () => {
    const qResult = CQueryParser.runQuery(editorText, schemaLookup, dataObjectLookup);
    const qResultStr = JSON.stringify(qResult, null, 2);
    setEditorText2(qResultStr);
  };

  const setEditorText = (newText: String) => {
    return setEditorTextAux(newText);
  };

  const validateDataObject = (data: string, schema: string) => {
    setEditorText2("Validating object...");
    let errors = CQueryParser.validateObject(data, schema, dataObjectLookup, schemaLookup);
    let validateResult = errors && errors.join('\n');
    
    setEditorText2(errors && errors.length == 0 ? "Validation passed" : validateResult);
    //console.log("validate", errors);
    return;
  };

  const formatText = () => {
    try{
      //var jsonToTx = JSON.parse(editorText);
      var jsonToTx = CQueryParser.parseJSON(editorText);

      var txToJson = JSON.stringify(jsonToTx, null, 2);
      console.log("strinified", txToJson);
      setEditorText(txToJson);
      setInfoText("");
      return txToJson;
    } catch(e){
      //setEditorText(editorText);
      setInfoText("Error parsing JSON");
      console.log("Error parsing JSON", e);
      return null;
    }
  };

  console.log("schemas", data, selection);

  const updateSelection = async () => {
    if(selection?.type == "schema") {
      await updateSchema();
    }else if(selection?.type == "dataObject") {
      await updateDataObject();
    }else if(selection?.type == "query") {
      await setQuery({data:editorText});
    }
  };


  const updateDataObject = async () => {
    //validate
    if(selection?.type != "dataObject") {
      console.error("not a DataObject type. Save cancelled");
      return;
    }
    
    const txForm = formatText();
    if(txForm == null) return;

    console.log("saving dataObject...", selection);

    if(selection?.data?.id == null){//add new
      await addDataObjectMutation({ variables:{
        input:{
          data: txForm,
          schemaId: selection?.data?.schema?.id,//TODO: fix...
        }
      }});
    }else{//update existing
      await updateDataObjectMutation({ variables:{
        id: selection?.data?.id,
        input:{
          data: txForm,//TODO: fix...
        }
      }});
    }
    var fetchResult = await dRefetch();
    setInfoText("Saved successfully");
    console.log("fetchResult(dataObject)", fetchResult, txForm);
  };

  const updateSchema = async () => {
    //validate
    if(selection?.type != "schema") {
      console.error("not a Schema type. Save cancelled");
      return;
    }
    
    const txForm = formatText();
    if(txForm == null) return;

    if(selection?.data?.id == null){//add new
      await addSchemaMutation({ variables:{
        input:{
          appId: appId,
          name: selection?.data?.name,
          schema: txForm,//TODO: fix...
        }
      }});
    }else{//update existing
      await updateSchemaMutation({ variables:{
        id: selection?.data?.id,
        input:{
          schema: txForm,//TODO: fix...
        }
      }});
    }
    var fetchResult = await refetch();
    setInfoText("Saved successfully");
    console.log("fetchResult(Schema)", fetchResult, txForm);
  };


  // return (
  //   <MainLayout>
  //     <ERDDiagramPlayground/>
  //     {/* <ERDDiagram/> */}
  //   </MainLayout>
  // );


  return (
    <MainColumnLayout>
      <ListContainer>
        <Button 
          variant="contained"
          onClick={() => {
            setQuerySelected( query || {data: "{\n\t\n}\n"})
          }}
        >Query</Button>
        <div>Schema:</div>
        {
          useSchemas?.map((s, i) => 
            <div key={i}>
              <Button onClick={() =>{
                console.log("setting schema...", s);
                setSchemaSelected(s);
                //setEditorText(s.schema);
              }}>
                {s.name}
              </Button>
            </div>
          )
        }
        <Button 
          variant="contained"
          onClick={() => {
            const name = prompt("Schema name:");
            //TODO: use camel case...
            if(name && name!=""){
              setSchemaSelected({
                name,
                schema: "{}"
              });
            }else alert("invalid name")
          }}
        >+ Add</Button>
        <div>Data Objects:</div>
        {
          useDataObjects?.map((d, i) => 
            <div key={i}>
              <Button onClick={() =>{
                console.log("setting data object...", d);
                setDataObjectSelected(d);
                //setEditorText(d.data);
              }}>
                {d.schema?.name}:{d.id}
              </Button>
            </div>
          )
        }
        <Button 
          variant="contained"
          onClick={() => {
            const schemaName = prompt("Schema name:");
            const schema = schemaName && schemaLookup[schemaName];
            if(schema){
              setDataObjectSelected({
                schema: schema,
                data: "{}",
              });
            }else alert("no such schema exists")
          }}
        >+ Add</Button>
      </ListContainer>
      <EditorContainer>
        <div>
          { selection?.type == "schema" ?
            "Schema : " + selection.data.name :"" }
          { selection?.type == "dataObject" ?
            "Data Object : " + selection.data?.schema?.name 
            + ":"+selection.data.id  :"" }
          { selection?.type == "query" ?
            "Query: ":""
          }


          {/* Schema: {schemaSelected?.name || " "} */}
          <Button
            variant="contained"
            onClick={formatText}
          >Format</Button>
          {selection?.type == "dataObject" &&
          <Button
            variant="contained"
            onClick={() => {
              //const dataStr = selection.data?.data;
              const schemaStr = schemaLookup[selection.data?.schema?.name]?.schema;
              validateDataObject(editorText, schemaStr);
            }}
          >Validate Data Object</Button>
          }
          { selection &&
            <Button
              variant="contained"
              onClick={updateSelection}
            >Save</Button>
          }
          { selection && (selection.type == "query")?
            <Button
              variant="contained"
              onClick={() => runQueryClick()}
            >Run</Button>
            :<></>
          }
          <span>{infoText}</span>
        </div>
        <MovableDividerContaier>
          <TextEditor
            // defaultValue={schemaSelected?.schema}
            setValue={setEditorText}
            value={editorText}
          />
          <TextEditor
            //defaultValue={"{}"}
            setValue={setEditorText2}
            value={editorText2}
          />
        </MovableDividerContaier>
      </EditorContainer>
    </MainColumnLayout>
  )
}


export default AppConsole;

