import { gql, useQuery } from "@apollo/client";
import { useState, createContext, useContext } from "react";
import { Observable, Subject } from 'rxjs';
// import { IBikeData } from "../components/BikeCard";
//import ReactDOM from "react-dom/client";

interface IUserProviderProps {
  children: React.ReactNode;
}

// export interface IBikeReservationData {
//   id: number
//   bike_id: number
//   user_id: number
//   bike?: IBikeData
//   user?: IUserData
//   seconds_start_time: number
//   seconds_end_time: number
// }

export interface IUserData {
  id: number
  email: string
  // first_name: string
  // last_name: string
  // role: string

  // bike_reservations?: IBikeReservationData
  // bike_reservation_count?: number
}


// window.emitEvent;
//  = (subscriber, data) => {
//   console.log('emitting:', data);
//   subscriber.next(data);
// });

// window.subject = new Subject<number>();
 
// subject.subscribe({
//   next: (v) => console.log(`observerA: ${v}`),
// });
// subject.subscribe({
//   next: (v) => console.log(`observerB: ${v}`),
// });
 
// subject.next(1);
// subject.next(2);



class UserContextData {


  userData: IUserData | null = null;

  eventListener = new Subject<IUserData>();

  constructor(){
    console.log(`---class "UserContextData" init`);
  }

  // console.log("--> init: UserContextData");

  // private onUserDataChange(userData: IUserData){

  // };

  // private userStateObserver = new Observable((subscriber) => {
  //   console.log('Hello');
  //   subscriber.next(this.userData);
  // });


  // constructor(onUserDataChange: (serData: IUserData) => void){

  // }

  getUseUserState(): IUserData | undefined {//() => IUserData | undefined {
    //return () => {
      console.log("getting useUserData hook",{...this.userData})
      const [state, setStateAux ] = useState<IUserData | undefined>(this.userData);



      this.eventListener.subscribe({
        next: (v) => {
          console.log("emitted event:", v);
          setStateAux(v);
        }
      });

      return state;
      //userData, onUserDataChange
    //}
  }
  
  setUserData(userData: IUserData) {
    this.userData = userData;
    this.eventListener.next(userData);
    console.log("called setUserData", userData);
  }
}

let userContextData = new UserContextData();
const UserContext = createContext(userContextData);

const MY_DATA = gql`
  query myData {
    myData {
      user {
        id
        email
      }
    }
  }
`;

export function UserProvider({ children }: IUserProviderProps) {
  const { data, loading, error } = useQuery(MY_DATA);


  //let userContextData = new UserContextData();

  // const [state, setState ] = useState();

  //if (loading) return <p>Loading...</p>;
  if (error){
      console.log("(UserProvider) not logged in", error);// TODO: verify
  } //return <p>Error :( {console.log("user error", error)}</p>;

  if(data){
    console.log("got login: ", data);
  }


  const setUserState_here = () => {

  };

  if(data?.myData?.user){// is logged in
    const useUserData = data.myData.user;
    userContextData.setUserData(data.myData.user);
  }

  console.log("UserProvider rerender...");

  return (
    <UserContext.Provider value={userContextData}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext(): UserContextData {
  return useContext(UserContext);
}


export function useUserState(): UserContextData {
  const ctx = useContext(UserContext);
  return ctx.getUseUserState();
}