import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import Highlight from 'react-highlight'


const EditorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 2px inset grey;
  & > pre{
    width: 100%;
    height: 100%;
    margin: 0px;
  }
`;

const EditorTextarea = styled.textarea`
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0px;
  background: transparent;
  color: transparent;
  caret-color: white;
  margin: 0px;
  padding: 13px;
  border: none;
  letter-spacing: -0.18px;
  tab-size: 2;
  overflow: hidden;
  white-space: nowrap;
`;

const CustomHightlight = styled(Highlight)`
  tab-size: 2;
  min-height: 100%;
`

const sample = `{
  sample: "12",
  etc: 123,
  arr: [1,2,3],
}`;


function txSelected(textarea){
  return textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
}


const TextEditor = ({ defaultValue, value, setValue }: any) => {
  const [text, setTextAux] = useState(defaultValue);
  
  console.log("text state:", value, defaultValue);
  const textareaEl = useRef(null);

  const onInput = (e) => {
    console.log("e", e);
    //const rows = e.target.value.split("\n").length;
    

    setValue(e.target.value);
    // e.target.rows = rows+1;
  };

  if(!setValue) setValue = setTextAux;
  // useEffect(()=>{
  //   setValue && setValue(defaultValue);
  // });

  var curText = value || text;
  console.log("curText", value, text, defaultValue);

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    console.log("key press e", e);
    if(e.keyCode == 9){//tab key
      e.preventDefault();
      //window.txDeb = textareaEl;
      //crude solution:
      if(!textareaEl.current) return;
      const txArea: HTMLTextAreaElement = textareaEl.current;

      const indexStart = txArea.selectionStart;
      const indexEnd = txArea.selectionEnd;

      const tSelected = txSelected(txArea);
      const selLines = tSelected.split('\n');

      console.log("tx selection", tSelected, selLines);

      let insertText = "";

      if(selLines.length > 1){//multi line
        insertText = '\t' + selLines.join('\n\t');
        console.log("ml insert", insertText);
      }else{//single line
        //....
        insertText = '\t';
      }

      const tx = txArea.value;
      const result = tx.substring(0,indexStart) + insertText + tx.substring(indexEnd);

      document.execCommand("insertText", false, insertText);

      setValue(result);
    }

  }

  const rows = curText && curText.split("\n").length;

  return <EditorContainer>
    <CustomHightlight className="json">
      { curText }
    </CustomHightlight>
    <EditorTextarea 
      ref={textareaEl}
      value={ curText } 
      onInput={onInput}
      onKeyDown={onKeyDown}
      rows={rows}
    />
    {/* <div>{highJs}</div> */}
  </EditorContainer>
};
export default TextEditor;