import ReactDOM from "react-dom";
import './styles.css';
import '../node_modules/highlight.js/styles/base16/classic-dark.css';
//refObjectAPI\frontend\node_modules\highlight.js\styles\base16\classic-dark.css
import { App } from "./App";
import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//import { UserProvider } from "./wrappers/UserProvider";
//import { SortAndFilterProvider } from "./wrappers/SortAndFilterProvider";
import { BrowserRouter } from "react-router-dom";
import DragEventsProvider from "./utils/dragEventsContext";
import { UserProvider } from "./providers/UserProvider";
import { AuthMatrixEntry } from "./utils/authMatrix";
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';

window.AuthMatrixEntry = AuthMatrixEntry;

const httpLink = createHttpLink({
  // uri: 'http://localhost:4000/graphql',
  uri: 'https://refapi.nethub.co.za/graphql',
});

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them

  console.log("retrieved token", token);
  return {
    headers: {
      ...headers,
      authorization: token ? token : "" //`Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   //link: authLink.concat(httpLink),
//   link: httpLink,
//   // uri: 'http://localhost/Ethereal/BikesBackend/public/graphql',
//   cache: new InMemoryCache(),
// });

ReactDOM.render(
  // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <ApolloProvider client={client}>
      <UserProvider>
        <BrowserRouter>
          <DragEventsProvider>
            <App/>
          </DragEventsProvider>
        </BrowserRouter>
      </UserProvider>
    </ApolloProvider>
  // </MuiPickersUtilsProvider>
  , document.getElementById("root")
);