import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AppCard from "./components/appCard";
import ERDDiagram from "./components/erdDiagram";
import LoginModal from "./components/loginModal";
import MovableDividerContaier from "./components/movableDividerContainer";
import TextEditor from "./components/textEditor";
import AppView from "./pages/AppView";
import ERDDiagramPlayground from "./pages/ERDiagramPlayground";
import { useUserState } from "./providers/UserProvider";
import { CQueryParser } from "./utils/CQuery";
import bannerImage from './assets/BannerTest2.png';

/*
title fonts:
https://fonts.google.com/specimen/Kanit?category=Serif,Sans+Serif,Display,Monospace&preview.text=Data%20Studio&preview.text_type=custom
https://fonts.google.com/specimen/Khand?category=Serif,Sans+Serif,Display,Monospace&preview.text=Data%20Studio&preview.text_type=custom
https://fonts.google.com/specimen/Share+Tech?category=Serif,Sans+Serif,Display,Monospace&preview.text=Data%20Studio&preview.text_type=custom
https://fonts.google.com/specimen/Saira+Condensed?preview.text=Data%20Studio&preview.text_type=custom


*/


const LogoH1 = styled.h1`
  text-align: center;
  font-family: 'Share Tech', sans-serif;
  
  font-family: 'Khand', sans-serif;
  font-variant: all-petite-caps;
  font-weight: normal;
  color: #ffffff;
  font-size: 72px;
  width: 100%;
`;

const Banner = styled.div`
  background: url("${bannerImage}");
  height: 100vh;
  width: 100%;
`;
const MainLayout = styled.div`
  position: absolute;
  top: 0;

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, #d8dce2, #bdc3d1);//magic gradient(ref)
  background: linear-gradient(0deg, #d8dce2, #bdc3d1 50%, #b9f0ffc7);
  // background: linear-gradient(0deg, #d8dce2, #bdc3d1);//magic gradient(ref)
  
`;

const MainColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  background: #eee;
`;

const HeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  // height: 100%;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  // background: #eee;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align-content: flex-start;
  // justify-content: flex-start;
  // align-items: flex-start;
  // background: #eee;
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Divider = styled.div`
  height: 4px;
  width: 100%;
  background: grey;
  border: 2px outset;
  cursor: ns-resize;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
`;
const ColumnSpacer = styled.div`
  flex-grow: 1;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 14px;
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 14px;
`;

const GET_USER_DATA = gql`
  query GetUserData {
    myData {
      apps {
        id
        name
        schemas {
          id
          name
          schema
        }
        dataObjects {
          id
          schemaId
          data
          schema {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_SCHEMAS = gql`
  query GetSchemas {
    schemas {
      id
      name
      schema
    }
    
  }
`;

const GET_DATA_OBJECTS = gql`
  query GetDataObjects {
    dataObjects {
      id
      data
      schema {
        id
        name
        schema
      }
    }
    
  }
`;

const UPDATE_SCHEMA = gql`
  mutation UpdateSchema($id: ID!, $input: UpdateSchemaInput!){
    updateSchema(id:$id, input: $input) {
      id
    }
  }
`;
const ADD_SCHEMA = gql`
  mutation AddSchema($input: AddSchemaInput!){
    addSchema(input: $input) {
      id
    }
  }
`;
const UPDATE_DATA_OBJECT = gql`
  mutation UpdateDataObject($id: ID!, $input: UpdateDataObjectInput!){
    updateDataObject(id:$id, input: $input) {
      id
    }
  }
`;
const ADD_DATA_OBJECT = gql`
  mutation AddDataObject($input: AddDataObjectInput!){
    addDataObject(input: $input) {
      id
    }
  }
`;

const ADD_APP = gql`
  mutation AddApp($input: AddAppInput!){
    addApp(input: $input) {
      id
    }
  }
`;

// const ADD_DATA_OBJECT = gql`
//   mutation AddDataObject($input: AddDataObjectInput!){
//     addDataObject(input: $input) {
//       id
//     }
//   }
// `;


type IObjectVisitFn<T> = (key: string, data: T) => void;
export type ILookup<T> = { 
  [name: string]: T
};

function iterateObject<T>(obj: ILookup<T>, onVisit: IObjectVisitFn<T>){
  Object.keys(obj).map((key) => {
      if(key == '__ref') return; // TODO: fix
      const data = obj[key];
      onVisit(key, data);
  });
}


const testQuery = `{
  "Books": {
    "title": {},
    "author": {
      "name": {}
    }
  }
}`;

interface ISchemaData {
  id: string
  name: string
  schema: string
}
interface IDataObjectData {
  id: string
  data: string
  schema?: ISchemaData
}

class Schema {
  schemaData: ISchemaData;
  schema: ILookup<string>;

  constructor(schemaData: ISchemaData){
    this.schemaData = schemaData;
    this.schema = JSON.parse(schemaData.schema);
  }
}

class DataObject {
  objectData: IDataObjectData;
  data: ILookup<string>;

  constructor(schemaData: IDataObjectData){
    this.objectData = schemaData;
    this.data = JSON.parse(schemaData.data);
  }
}

interface IParsedDataOnFieldData{
  fieldName: string
  isRequired: boolean
  dataType: string
  hasField: boolean
  data: Object
  referencedSchemaMeta: Object
  referencedSchema: Object
  referencedData: Object
  referencedDataMeta: Object
}

// function CQueryParseJSON(jsObjectText: string){
//   const recursiveFn = new Function(`return ${jsObjectText};`);
//   return recursiveFn();
// }

const StateTest = () => {
  const userState = useUserState();
  
  console.log("--> rerender stateTestButton", userState);
  return (
    <div>stateTest:{userState?.email}</div>
  );
};


const DefaultView = () => {

}

interface IPage {
  name: string
  data?: any
}


//mock class
class AppModel {
  id: string;
  name: string;
  schemas: any[];
  dataObjects: any[];

  constructor({id, name, schemas, dataObjects}: any){
    this.id = id;
    this.name = name;
    this.schemas = schemas;
    this.dataObjects = dataObjects;
  }
}


export const App = () => {
  const { loading:uLoading, error:uError, data:uData, refetch:uRefetch } = useQuery(GET_USER_DATA);
  const [ loginOpen, setLoginOpen ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState<IPage>({name: "apps"});

  const [ addAppMutaion ] = useMutation(ADD_APP);

  // useEffect(() => {
  //   console.log("useApps?", useApps);
  //   !useApps && setCurrentPage(
  //     <>
  //       <h3>Apps</h3>
  //       <MainColumnLayout>
  //         <div>
  //           {
  //             useApps?.map(a => <AppCard appData={a}/>)
  //           }
  //         </div>
  //       </MainColumnLayout>
  //     </>
  //   );
  // }, [useApps]);

  //

  if (uLoading) return <p>Loading...</p>;
  if (uError) {
    uError && console.log("uError", uError);
    return <>
      <p>Error loading schemas :( </p>
    </>;
  }

  //

  const addNewApp = async (name: string) => {
    const appResult = await addAppMutaion({variables:{
      input:{
        name
      }
    }});
    console.log("got new app", appResult);
    await uRefetch();
  };



  // let useSchemas;
  // let useDataObjects;
  let useApps;

  console.log("--got user data:", uData);

  if(uData?.myData){
    //TODO: select app...
    // useApps = uData.myData.apps && uData.myData.apps.map(a =>{
    //   const am = new AppModel(a);
    //   new AuthMatrixEntry(am, am.id, "r");
    //   return am;
    // });

    useApps = uData.myData.apps;


    // useSchemas = uData.myData.apps[0]?.schemas;
    // useDataObjects = uData.myData.apps[0]?.dataObjects;
  } else {//not logged in
    // useSchemas = data?.schemas;
    // useDataObjects = dData?.dataObjects;
  }
  // return (
  //   <MainLayout>
  //     <ERDDiagramPlayground/>
  //     {/* <ERDDiagram/> */}
  //   </MainLayout>
  // );

  const appsView =   
    <>
      <div>
        <h3>Apps</h3><Button onClick={async() => {
          const name = prompt("App name:");
          if(name && name!= ""){
            await addNewApp(name);
            //...do something next...
          }else{
            console.error("invalid app name");
          }
          
        }}>Create new app +</Button>
      </div>
      <MainColumnLayout>
        <CardsContainer>
          {
            useApps?.map(a => 
              <CardContainer>
                <AppCard 
                  appData={a} 
                  onSelect={() => setCurrentPage({
                    name: "app",
                    data: a,
                  })}
                />
              </CardContainer>
            )
          }
        </CardsContainer>
      </MainColumnLayout>
    </>
  ;

  return (<>
    <Banner/>
    <MainLayout>
      <LoginModal open={loginOpen} setOpen={setLoginOpen}/>
      <HeaderLayout>
        <LogoH1>Data Studio</LogoH1>
        {/* <img src={bannerImage}/> */}
      {/* <Typography textAlign="center" variant="h1">Data Studio</Typography> */}
        {/* <h1>Ref Objects</h1> */}
        <ColumnSpacer/>
        <ButtonContainer>
          <StateTest/>
          <Button variant="contained" onClick={()=>setLoginOpen(true)}>Login</Button>
        </ButtonContainer>
      </HeaderLayout>
      { currentPage?.name == "apps" && 
        appsView
      }
      { currentPage?.name == "app" && 
        <>
          <h3>App: {currentPage.data.name}</h3>
          <AppView appId={currentPage.data.id}/>
        </>
      }
      {/* <MainColumnLayout>
        <div>
          {
            useApps?.map(a => <AppCard appData={a}/>)
          }
        </div>
      </MainColumnLayout> */}
        {/* <AppView/> */}
      
    </MainLayout>
  </>)
}

