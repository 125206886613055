import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { CrudDataGrid, DataGridField, ICrudDataGridProps, IDataGridField, IDataGridFieldComponentFunction } from './permissionsEditor';
import { IUpsertFieldSchema } from './crud/upsertIemModal';
import { ADD_DATA_OBJECT, UPDATE_DATA_OBJECT } from '../pages/AppConsole';
import { CQueryParser } from '../utils/CQuery';
import { Checkbox } from '@mui/material';


// interface ICrudDataGridProps<TData> {
//   fields: IDataGridField[]
//   rowData: IDataGridRow[]
//   appId: number
//   //upsertSchema: IUpsertFieldSchema[]
//   upsertModalProps: IUpsertItemModalProps<TData>
//   handleUpsert: (newData: TData) => Promise<TData>
//   handleDelete: (newData: TData) => Promise<TData>
//   getExpandedContent?: (rowData: IDataGridRow) => ReactNode //TODO: Add generic type..
// }


const fields: IDataGridField[] = [
  { fieldName:"id", fieldText: "ID"},
  { fieldName:"userId", fieldText: "User ID"},
  { fieldName:"authType", fieldText: "Authorization Type"},
  { fieldName:"crudRuleString", fieldText: "CRUD Rule"},
  { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];

const upsertSchema: IUpsertFieldSchema[] = [
  { fieldName:"userId", fieldText: "User ID", required: true},
  { fieldName:"authType", fieldText: "Authorization Type", required: true},
  { fieldName:"crudRuleString", fieldText: "CRUD Rule", required: true},
  { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];


// schema: permissionsUpsertSchema,
// fields: permissionFields,
// rowData: permissions, 

const propsTp: ICrudDataGridProps<any> = {
  fields: [{ fieldName: "f1", }]

}

const inputProps = {
   fields,
//   rowData: IDataGridRow[]
//   appId: number
    upsertSchema,
//   upsertModalProps: IUpsertItemModalProps<TData>
//   handleUpsert: (newData: TData) => Promise<TData>
//   handleDelete: (newData: TData) => Promise<TData>
//   getExpandedContent?: (rowData: IDataGridRow) => ReactNode //TODO: Add generic type..
};

const BoolInput: IDataGridFieldComponentFunction = DataGridField.base(({field, data, onInput})=>{
  return <Checkbox 
    checked={data.value}
    onChange={(e)=>{ onInput(e.target.checked) }}
    {...field.inputProps}
  />;
});

export default function DataObjectDataGrid({dataObjects, schema, schemaId}: any) {
  const [ addNewDataObject ] = useMutation(ADD_DATA_OBJECT);
  const [ updateDataObject ] = useMutation(UPDATE_DATA_OBJECT);
  // const [ deletePermission ] = useMutation(DELETE_PERMISSION); 

  console.log("-DataObjectDataGrid props", dataObjects, schema, schemaId);


  
  const rowData = dataObjects;
  const schemaTp: IUpsertFieldSchema[] = Object.keys(schema).map((k: IUpsertFieldSchema)=> {
    const typeDefs = CQueryParser.parseTypeDefinition(schema[k]);
    let componentFn = null;
    switch(typeDefs.dataType){
      case "boolean":
        componentFn = BoolInput;
        break;
    }
    return { fieldName: k, componentFn };
  });
  const fieldsTp: IUpsertFieldSchema[] = Object.keys(schema).map((k: IUpsertFieldSchema)=> {
    const typeDefs = CQueryParser.parseTypeDefinition(schema[k]);
    let componentFn = undefined;
    let inputProps = undefined;
    switch(typeDefs.dataType){
      case "boolean":
        
        componentFn = ({data}) => <input type="checkbox" checked={data.value} disabled/>;
        inputProps = { disabled: true };
        break;
    }
    return { fieldName: k, componentFn, inputProps };
  });

  //const fieldsTp = schemaTp;

  const rowDataTp: IDataGridRow[] = dataObjects.map(dO => {
    const values = Object.keys(dO).reduce((r,k) => {
      r[k] = {value:dO[k]};
      return r;
    }, {});
    return { values };
  });

  console.log("-DataObjectDataGrid transformed ", rowDataTp, schemaTp);


  //return <div>Data objects Data grid</div>;
  /*
  required?: boolean
  type?: string
  // inputProps?: any
  readonly?: boolean
  placeholder?: string
  validators?: ((text: string) => string)[]
  hidden?: boolean
  
  */


  // const handleDelete = async(data: any) => {
  //   if(data.id){//add new
  //     console.log("delete permission?", data);
  //     const deletePermissionResult = await deletePermission({ variables: { id: data.id} });
  //     return deletePermissionResult;
  //   }else{//update
  //     console.log("attempt to delete invalid permission", data);
  //   }
  //   //TODO: refetch...
  // };
  const handleUpsert = async(data: any) => {
    //TODO: validate on schem
    console.log("got OD data:", data);
    const cp = {...data};
    delete cp.id;

    const useDataTx = JSON.stringify(cp);

    //return;

    if(data.id == null){//add new
      await addNewDataObject({ variables:{
        input:{
          data: useDataTx,
          schemaId,//TODO: fix...
        }
      }});
    }else{//update existing
      await updateDataObject({ variables:{
        id: data.id,
        input:{
          data: useDataTx,//TODO: fix...
        }
      }});
    }


    // if(!data.id){//add new
    //   console.log("add permission?", data);
    //   const addPermissionResult = await addNewDataObject({ variables: { input: data} });
    //   return addPermissionResult;
    // }else{//update
    //   console.log("update permission?", data);
    //   const { id, userId, crudRuleString, permissionsCrudRuleString, authType } = data;
    //   const updatePermissionResult = await updatePermission({ variables: { input: {id, userId, crudRuleString, permissionsCrudRuleString, authType}} });
    //   return updatePermissionResult;
    // }
    //TODO: refetch...
  };

  // const getExpandedContent = (rowData: IDataGridRow) => {
  //   return <PermissionAttributesDataGrid attributePermissions={rowData?.attributes?.value || []} permissionId={rowData?.id?.value}/>;
  // };

  //return <span>data object</span>;

  const upsertModalProps = {      
    entityName:"Data Objects",
    data:{
      // targetTypeName: "DataObject",
      // targetId: appId,
      schemaId: schema.id,
    },
    schema: schemaTp,
    upsertFunction: handleUpsert,
  };

  console.log("passing args: ", fieldsTp, dataObjects);

  return CrudDataGrid<any>({ 
    fields: fieldsTp,
    rowData: dataObjects, 
    //appId, 
    // handleUpsert, 
    // handleDelete,
    upsertSchema,//: permissionsUpsertSchema,
    
    upsertModalProps,
    // getExpandedContent,
  });
}






















// function getHeaders(schema: any){
//   console.log("schema??", schema);
//   return Object.keys(schema).map(k => ({
//     field: k,
//     headerName: k,
//   }));


//   const columns: GridColDef[] = [
//     { field: 'id', headerName: 'ID', width: 90 },
//     {
//       field: 'firstName',
//       headerName: 'First name',
//       width: 150,
//       editable: true,
//     },
//     {
//       field: 'lastName',
//       headerName: 'Last name',
//       width: 150,
//       editable: true,
//     },
//     {
//       field: 'age',
//       headerName: 'Age',
//       type: 'number',
//       width: 110,
//       editable: true,
//     },
//     {
//       field: 'fullName',
//       headerName: 'Full name',
//       description: 'This column has a value getter and is not sortable.',
//       sortable: false,
//       width: 160,
//       valueGetter: (params: GridValueGetterParams) =>
//         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//     },
//   ];

//   return columns;
// }

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

// const TableCustom = styled.table`
//   text-align: left;
//   border-spacing: 0px;
//   //border: 2px solid #ddd;
//   border-bottom: none;
//   width: 100%;
//   // max-width: 450px;
//   & td,th{
//     border: 1px solid #ddd;
//     padding: 5px;
//   }
//   & th {
//     font-weight: bold;
//     background: #eee;
//   }
//   & tr {
//     // cursor: default;
//   }
//   // & tr:last-child:hover {
//   //   background: #eee;
//   //   cursor: pointer;
//   // }
// `;


// export function DataObjectDataGrid({schema, dataObjects}) {
//   // const columns = getHeaders([{name: "id"}, {name: "lastName"}]);
//   const columns = getHeaders(schema);

//   //Object.keys(schema).map(k => ({
//   const fieldKeys = Object.keys(schema);
//   return (
//     <TableCustom>
//       <thead>
//       <tr>
//         {
//           columns.map(c => <th>{c.headerName}</th>)
//         }
//       </tr>
//       </thead>
//       <tbody>
//       {
//         dataObjects.map(dO => 
//           <tr contentEditable>
//             {
//               fieldKeys.map(k => <td>{dO[k]}</td>)
//             }
//           </tr>
//         )
//       }
//       </tbody>
//     </TableCustom>
//   );

//   return (
//     <Box sx={{ height: 400, width: '100%' }}>
//       <DataGrid
//         // rows={rows}
//         rows={dataObjects}
//         columns={columns}
//         pageSize={5}
//         rowsPerPageOptions={[5]}
//         // checkboxSelection
//         disableSelectionOnClick
//         experimentalFeatures={{ newEditingApi: true }}
//       />
//     </Box>
//   );
// }

// export function DataObjectDataGrid_prev({schema, dataObjects}) {
//   // const columns = getHeaders([{name: "id"}, {name: "lastName"}]);
//   const columns = getHeaders(schema);

//   return (
//     <Box sx={{ height: 400, width: '100%' }}>
//       <DataGrid
//         // rows={rows}
//         rows={dataObjects}
//         columns={columns}
//         pageSize={5}
//         rowsPerPageOptions={[5]}
//         // checkboxSelection
//         disableSelectionOnClick
//         experimentalFeatures={{ newEditingApi: true }}
//       />
//     </Box>
//   );
// }
