import styled from "@emotion/styled";
import { faFont, faHashtag, faPlus, faRightToBracket, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataArrayIcon from '@mui/icons-material/DataArray';
import { ListItemIconCustom } from "../pages/AppView";
import { CQueryParser } from "../utils/CQuery";


const TableCustom = styled.table`
  border-spacing: 0px;
  border: 2px solid #ddd;
  border-bottom: none;
  width: 100%;
    max-width: 450px;
  & td {
    border-bottom: 2px solid #ddd;
    padding: 5px 0px 5px 10px;
  }
  & tr {
    cursor: default;
  }
  & tr:last-child:hover {
    background: #eee;
    cursor: pointer;
  }
`;


const LightText = styled.span`
  color: #aaa;
`;

const typeIconMap = {
  "string": <FontAwesomeIcon icon={faFont}/>,
  "number": <FontAwesomeIcon icon={faHashtag}/>,
  "boolean": <FontAwesomeIcon icon={faToggleOn}/>,
  "array": <DataArrayIcon/>,
  "reference": <FontAwesomeIcon icon={faRightToBracket}/>,
};


export default function SchemaEditor({schema}: any){
  const fieldNames = schema  && Object.keys(schema);
  const fields = fieldNames?.map(k => {
    const tp = CQueryParser.parseTypeDefinition(schema[k]);
    tp.fieldName = k;
    return tp;
  });
  
  console.log("fields?", fields);

  return (
    <TableCustom>
      <thead></thead>
      <tbody>
        {
          fields.map(f => 
            <tr>
              <td>
                <span>
                  <ListItemIconCustom>
                    { typeIconMap[f.dataType] ? typeIconMap[f.dataType] : typeIconMap.reference }
                    { f.isArray && typeIconMap.array }
                  </ListItemIconCustom>
                </span>
              </td>
              <td>
                <span>{f.fieldName}</span>
              </td>
              <td>
                <LightText>({f.isArray && "Array of "}{!typeIconMap[f.dataType] && "reference to: "}{f.dataType})</LightText>
              </td>
              <td>
                <LightText>{f.isRequired && "Required field"}</LightText>
              </td>
            </tr>
          )
        }
        <tr><td>
          <ListItemIconCustom>
            <FontAwesomeIcon icon={faPlus}/>  
          </ListItemIconCustom>
        </td>
        <td><span>Add field </span></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </TableCustom>
  );
}