import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ListItem, TextField, List } from '@mui/material';
import { IDataField } from '../permissionsEditor';
import { useEffect } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface IConfirmModalProps {
  open: boolean
  setOpen: (isOpen: boolean) => void
  headerText?: string
  message: string
  onConfirm: (hasConfirmed: boolean) => void
}


export default function ConfirmModal({
    open,
    setOpen,
    headerText,
    message,
    onConfirm,
  }: IConfirmModalProps) {
  //const [newData, setNewData] = React.useState({...data});


  // const handleSave = async() => {
  //   try{
  //     const upsertResult = await upsertFunction(newData);
  //     console.log("upsertResult", upsertResult);
  //   } catch(e){
  //     console.error(e);
  //   } 
  // };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          { headerText }
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          { message }
        </Typography>
        <Button variant="contained"
          onClick={() =>{ onConfirm(true)}}
        >Confirm</Button>
        <Button variant="contained"
          onClick={() =>{ onConfirm(false)}}
        >Cancel</Button>
      </Box>
    </Modal>
  );
}
