
type IObjectVisitFn<T> = (key: string, data: T) => void;
type ILookup<T> = { 
  [name: string]: T
};

function iterateObject<T>(obj: ILookup<T>, onVisit: IObjectVisitFn<T>){
  Object.keys(obj).map((key) => {
    if(key == '__ref') return; // TODO: fix
    const data = obj[key];
    onVisit(key, data);
  });
}

function CQueryParseJSON(jsObjectText: string, scopeVariables?: []){

    const scopeVariablesTx = scopeVariables ? scopeVariables.reduce((r,c)=>
        r + `const ${c} = {};\n`
        , "")
    :"";

    const recursiveFn = new Function(`${scopeVariablesTx}return ${jsObjectText};`);
    const result = recursiveFn();
    if(result == null) throw "Failed to parse CQuery syntax";
    return result;
}

export class CQueryParser {

    static parseJSON = CQueryParseJSON;

    static runQuery(queryText, schemaLookup, dataObjectLookup){
      // need to define...
      const result = {};
      var queryObj = queryText && this.parseJSON(queryText);
      //console.log("queryObj", queryObj);
    
      iterateObject(queryObj, (k, q) => {//each query...
        switch(k){
          case "Books"://??
            const qObj = this.queryObjects("Book", q, schemaLookup, dataObjectLookup);
            result["Books"] = qObj;
            break;
        }
      });
      return result;
    }
  
    static queryObjects(schemaName, objectQuery, schemaLookup, dataObjectLookup){
      const schemaObj = schemaLookup[schemaName];
      const schema = schemaObj && JSON.parse(schemaObj.schema);
      const schemaId = schemaObj.id;
    
      const results = [];
    
      iterateObject(dataObjectLookup, (key, dataObject) => {
        if(dataObject.schema.id == schemaId){
          const data = JSON.parse(dataObject.data);
    
          const qObj = this.queryObject(data, schemaObj, objectQuery, schemaLookup, dataObjectLookup);
          results.push(qObj);
        }
      });
      return results;
    }
  
    static queryObject(data, schemaObj, objectQuery, schemaLookup, dataObjectLookup){
      //const schemaObj = schemaLookup[schemaName];
      const schema = schemaObj && JSON.parse(schemaObj.schema);
    
      //get first obj for now...
    
      const result = {};
    
      console.log("data", data);
      console.log("schemaObj", schemaObj);
      console.log("schema", schema);
      iterateObject(objectQuery, (key, q) => {
        const isRequired = schema && /!$/.test(schema[key]);//ends with !
        var prTp = schema && /\w+/.exec(schema[key]);
        var dataType = prTp && prTp[0];
        const hasKey = data.hasOwnProperty(key);
        //const objData = dataObj[key];
        //const typeSchema = schemaLookup[dataType];
        //const referencedData = objData && dataObjectLookup[objData];
    
        
    
        console.log("obj query", key, q);
        console.log("schema key?", schema[key]);
        console.log("parsed...", {
          isRequired,
          key,
          hasKey,
          dataType,
        });
    
        const curData = data[key];
        let dataTemp;
    
        switch(true){
          case dataType == "string":
          case dataType == "number":
          case dataType == "boolean":
            dataTemp = curData;
            break;
          case dataType && dataType != ""://object??
            //TODO: verify type...
            console.log("data?", data, curData, referenceData);
            var referenceData = dataObjectLookup[curData];
            var referenceDataParsed = JSON.parse(referenceData.data);
    
            const typeSchema = schemaLookup[dataType];
            console.log("referenced data", curData, referenceDataParsed, typeSchema);
    
            dataTemp = this.queryObject(referenceDataParsed, typeSchema, q, schemaLookup, dataObjectLookup);
            break;
        }
    
    
        result[key] = dataTemp;
    
    
        // switch(k){
        //   case "Books":
        //     //
        //     queryObject(q);
        //     break;
        // }
      });
    
      return result;
    }

    static parseTypeDefinition(typeDefinition: string) {
      const isRequired = /!$/.test(typeDefinition);//ends with !
      const prTp = /\w+/.exec(typeDefinition);
      const dataType = prTp && prTp[0];
      const isArray = /\[](!|)$/.test(typeDefinition);

      return {
        isRequired,
        dataType,
        isArray,
      };
    }
  
    static parseDataOnField(
      dataObject, fieldName, typeDefinition: string, dataObjectLookup, schemaLookup
    ){

      const {
        isRequired,
        dataType,
        isArray
      } = this.parseTypeDefinition(typeDefinition);

      //const isRequired = /!$/.test(typeDefinition);//ends with !
      //var prTp = /\w+/.exec(typeDefinition);
      //var dataType = prTp && prTp[0];
      const hasKey = dataObject.hasOwnProperty(fieldName);
      const objData = dataObject[fieldName];
      const typeSchemaObject = dataType && schemaLookup[dataType];
      const typeSchema = typeSchemaObject && JSON.parse(typeSchemaObject.schema);
      const referencedDataObject = objData && dataObjectLookup[objData];
      const referencedData = referencedDataObject && JSON.parse(referencedDataObject.data);
      
  
      return {
        fieldName,
        isRequired,
        dataType,
        isArray,
        hasField: hasKey,
        data: objData,
        referencedSchemaMeta: typeSchemaObject,
        referencedSchema: typeSchema,
        referencedData,
        referencedDataMeta: referencedDataObject,
      }
    }
    static validateValueOnField( // explicit value (string, number, etc)
      // dataObject, fieldName, typeDefinition, dataObjectLookup, schemaLookup
      parsedDataOnFieldData: IParsedDataOnFieldData
    ){
      const {
        fieldName,
        isRequired,
        dataType,
        isArray,
        hasField,
        data,
        referencedSchemaMeta,
        referencedSchema,
        referencedData,
        referencedDataMeta
      } = parsedDataOnFieldData;
      //this.parseDataOnField(dataObject, fieldName, typeDefinition, dataObjectLookup, schemaLookup);
    
      const errors = [];
  
      if(!hasField && isRequired) errors.push(`required field "${fieldName}" not defined`);
      
      //validate data type
      if(hasField){
        if(isArray){
          console.log("got array type");
          const allAreOfType = data && Array.isArray(data) && data.find(item => typeof item != dataType) == null;
          console.log("allAreOfType", allAreOfType, data, Array.isArray(data), dataType);

          if(!allAreOfType) errors.push(
            `invalid data type in array on field "${fieldName}". Got '??' but expected '${dataType}[]'`
          );
        }
        else{
          if(typeof data != dataType) errors.push(
            `invalid data type on field "${fieldName}". Got '${typeof data}' but expected '${dataType}'`
          );
        }
      }else{
        errors.push(`no such field "${fieldName}" on object "??".`);//TODO: schema name???
      }
      return errors;
    }
  
    static validateObjectOnField(
      //dataObject, fieldName, typeDefinition, dataObjectLookup, schemaLookup
      parsedDataOnFieldData: IParsedDataOnFieldData
    ){
  
      const {
        fieldName,
        isRequired,
        dataType,
        isArray,
        hasField,
        data,
        referencedSchemaMeta,
        referencedSchema,
        referencedData,
        referencedDataMeta
      } = parsedDataOnFieldData;
      //this.parseDataOnField(dataObject, fieldName, typeDefinition, dataObjectLookup, schemaLookup);
  
      const errors = [];
  
      console.log("Custom data type...", dataType, referencedSchemaMeta);
      if(referencedSchemaMeta){
        if(!hasField && isRequired) errors.push(`required field "${fieldName}" not defined`);
        if(hasField && typeof data != "string") errors.push(`invalid string data for reference field "${fieldName}:${dataType}"`);
        //check if data exists...
        else if(hasField){
          if(!referencedData) errors.push(`no such referenced object (${data}) for field "${fieldName}"`)
          else if(referencedDataMeta.schema.id != referencedSchemaMeta.id){
            errors.push(`referenced object is not of type "${dataType}"`);
          }else{
            //valid ref...
            console.log("--> got ref", referencedData);
          }
        }
  
      }else{
        errors.push(`no such data type "${dataType}"`);
      }
  
      return errors;
    }
  
    static validateObject(data: string, schema: string, dataObjectLookup, schemaLookup){
      let dataObj;
      let schemaObj;
  
      let errors = [];
  
      try{
        dataObj = this.parseJSON(data);
        schemaObj = this.parseJSON(schema);
      } catch(e){
        console.log("parse failed...", data, schema);
        //setEditorText(editorText);
        errors.push("failed to parse JSON");
        return errors;
      }
      let errorsTp = [];
      //Allow extra attributes? 
  
      //validate on schema...
      iterateObject(schemaObj, (key: string, typeDef: string) => {
        const isRequired = /!$/.test(typeDef);//ends with !
        var prTp = /\w+/.exec(typeDef);
        var dataType = prTp && prTp[0];
        const hasKey = dataObj.hasOwnProperty(key);
        const objData = dataObj[key];
        const typeSchema = schemaLookup[dataType];
        const referencedData = objData && dataObjectLookup[objData];
  
        const parsedDataOnField = CQueryParser.parseDataOnField(dataObj, key, typeDef, dataObjectLookup, schemaLookup);
  
        console.log("parseDataOnField", 
          CQueryParser.parseDataOnField(dataObj, key, typeDef, dataObjectLookup, schemaLookup),
          "for", dataObj, key, typeDef
        );
  
        
  
        console.log("--it", key, data);
        switch(true){
          case dataType == "string":
          case dataType == "number":
          case dataType == "boolean":
            errorsTp = CQueryParser.validateValueOnField(parsedDataOnField);
            errors =  [...errors, ...errorsTp];
            break;
          case dataType != null: // 
            //console.log("Custom data type...", dataType, typeSchema);
            errorsTp = CQueryParser.validateObjectOnField(parsedDataOnField);
            errors =  [...errors, ...errorsTp];
            //console.log("---> validate", errorsTp);
            break;
        }
      })
  
      //reject extra attributes...
      iterateObject(dataObj, (key, data) => {
        const hasKey = schemaObj.hasOwnProperty(key);
        if(!hasKey) errors.push(`field "${key}" is not defined in schema`);
  
        let errorsTp = [];
      })
  
      return errors;
    }
  
  }