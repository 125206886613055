import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { useDragEvents } from "../utils/dragEventsContext";

const MovableDivider = styled.div`
  height: 8px;
  width: 100%;
  background: #555;
  border: 2px outset;
  cursor: ns-resize;
  &:hover{
    background: grey;
  }
  &:active{
    background: white;
  }

`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
const DividerContainer = styled.div`
  height: 100%;
  width: 100%;
`;

// let msDrag;
// let msDragDrop;

// console.log("ms move define", window);
// window.addEventListener("mousemove", (e) => {
//   //console.log("ms move?");
//   if(msDrag){
//     msDrag(e);
//   }
// });
// window.addEventListener("mouseup", (e) => {
//   if(msDrag){
//     msDrag = null;
//   }
//   if(msDragDrop){
//     msDragDrop(e);
//     msDragDrop = null;
//   }
// });

const MovableDividerContaier = ({children}: any) => {
  const dragEvent = useDragEvents();

  console.log("dragEvent", dragEvent);
  // const {
  //   msDrag,
  //   msDragDrop
  // } = dragEvent;


  const childCount = children.length;
  //const [sizes, setSizes] = useState(children.map(c => 100/childCount)); //percent
  const refs = children.map(c => useRef()); //percent
  const mainRef = useRef();
  let sizes = children.map(c => 100/childCount); //percent

  useEffect(()=>{
    const rect = mainRef.current.getBoundingClientRect();
    //let sizesTp = [];//[...sizes];
    //equal spacing
    sizes = children.map(c => rect.height/childCount - 8);
    sizes.forEach((s, i) => {
      refs[i].current.style.height = s + "px";
    });

    //setSizes(sizesTp);

    // sizes = children.map((c, i) => {
    //   const rectTp = refs[i].current.getBoundingClientRect();
    //   return rectTp.height;
    // });


  })

  return <MainContainer ref={mainRef}>
    {
      children.map((c, i) => {
        return <>
          <div ref={refs[i]}>{c}</div>
          {i!=childCount-1 && <MovableDivider onMouseDown={(e)=>{
          
            const msDownY = e.pageY;
            const sizesMsDown = [...sizes];
            //const sizesTp = [...sizes];
            dragEvent.msDrag = (eMove) => {
              const dif = eMove.pageY - msDownY;
              sizes[i] = sizesMsDown[i]+dif;
              sizes[i+1] = sizesMsDown[i+1]-dif;
              sizes.forEach((s, i) => {
                refs[i].current.style.height = (s) + "px";
              });

              //setSizes(sizesTp);
            }
          }}/>}
          
        </>
      })
    }
  </MainContainer>;
}
export default MovableDividerContaier;