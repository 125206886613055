import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PanoramaIcon from '@mui/icons-material/Panorama';
import styled from '@emotion/styled';

export const PlaceholderImage = styled(PanoramaIcon)`
  width: 100%;
  height: 100%;
`;

interface IAppData {
  id: string
  name: string
}

interface IAppCardProps {
  appData: IAppData
  onSelect: (app: IAppData) => void
}

export default function AppCard({ appData, onSelect }: IAppCardProps) {
  return (
    <Card sx={{ maxWidth: 200 }} elevation={2} style={{padding:"10px", width: "150px"}}>
      <CardMedia
        style={{ padding: "10px", height: "100px"}}
        // component="div"
        height="100"
        // image="/static/images/cards/contemplative-reptile.jpg"
        // alt="green iguana"
      >
        <PlaceholderImage/>
      </CardMedia>
      <CardContent>
        <Typography variant="h6" componenxt="div" textAlign='center'>
          {appData.name}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography> */}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => onSelect && onSelect(appData)}>Open</Button>
        {/* <Button size="small">Learn More</Button> */}
      </CardActions>
    </Card>
  );
}
