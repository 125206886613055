import * as React from 'react';
import Box from '@mui/material/Box';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListItemButtonCustom, ListItemIconCustom } from '../pages/AppView';
import { ReactNode, useState } from 'react';
import UpsertItemModal, { IUpsertFieldSchema, IUpsertItemModalProps } from './crud/upsertIemModal';
import { gql, useMutation } from '@apollo/client';
import { ILookup } from '../App';
import { Button, Checkbox, FormControl, InputLabel, ListItemButton, MenuItem, Select } from '@mui/material';
import ConfirmModal from './confirmModal';


const ADD_NEW_PERMISSION = gql`
  mutation AddPermission($input: AddPermissionInput!){
    addPermission(input: $input) {
      id
    }
  }
`;
const UPDATE_PERMISSION = gql`
  mutation UpdatePermission($input: UpdatePermissionInput!){
    updatePermission(input: $input) {
      id
    }
  }
`;
const DELETE_PERMISSION = gql`
  mutation DeletePermission($id: ID!){
    deletePermission(id: $id) {
      id
    }
  }
`;


const ADD_NEW_ATTRIBUTE_PERMISSION = gql`
  mutation AddAttributePermission($input: AddAttributePermissionInput!){
    addAttributePermission(input: $input) {
      id
    }
  }
`;


const TableCustom = styled.table`
  text-align: left;
  border-spacing: 0px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 11pt;
  //border: 2px solid #ddd;
  border-bottom: none;
  width: 100%;
  // max-width: 450px;
  & td,th{
    border: 1px solid #ddd;
    padding: 5px;
  }
  & th {
    font-weight: bold;
    background: #eee;
  }
  & tr {
    // cursor: default;
  }
  // & tr:last-child:hover {
  //   background: #eee;
  //   cursor: pointer;
  // }
`;



export interface IDataField {
  fieldName: string
  fieldText?: string
  componentFn?: IDataGridFieldComponentFunction
  inputProps?: any
}

//const
export interface IDataGridField extends IDataField {
  // field: string
  //headerName: string
  // componentFn?: IDataGridFieldComponentFunction
  // inputProps?: any
}




interface IDataGridData {
  // field: string
  // headerName: string
  // componentFn?: IDataGridFieldComponentFunction
  value: any
}

interface IDataFieldComponentProps {
  field: IDataGridField
  data: IDataGridData
  onInput: (value: any) => void
}

interface IDataGridFieldComponentProps extends IDataFieldComponentProps {
  // field: IDataGridField
  // data: IDataGridData
}


type IDataGridFieldComponent<DataType> = React.FC<IDataGridFieldComponentProps>//IDataGridFieldComponentProps>;

// const FieldSelect: IDataGridFieldComponent = (props: IDataGridFieldComponentProps) => (
//   CompTp(props)
// );

export type IDataGridFieldComponentFunction = (props: IDataGridFieldComponentProps) => React.ReactNode;//IDataGridFieldComponent;

export class DataGridField {

  static base(componentFn: IDataGridFieldComponentFunction): IDataGridFieldComponentFunction {
    // if(componentFn) {
      const CompTp = (props: IDataGridFieldComponentProps) => {
        return componentFn(props);
      };
      return CompTp;
    // }
    // {
    //   return (props: IDataGridFieldComponentProps) => {
    //       return componentFn(props);
    //       // return <CompTp {...props}/>;
    //     };
    //   }
    
    // const CompTp = ({value}: IDataGridFieldComponentProps): IDataGridFieldComponent => {
    //   return <span>{value}</span>;
    // };
    // return CompTp;
  }
}



// const CompTp = ({ fields }: IDataGridFieldComponentProps) => {
//   return <div>{value}</div>;
// };

const DataGridSelectComponent: IDataGridFieldComponentFunction = DataGridField.base(({field, data, onInput})=>{
  const {options} = field.inputProps;
  console.log("got options?", options);

  const fieldName = field.fieldText || field.fieldName ;
  return <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">{ fieldName }</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={data.value}
      label={ fieldName }
      onChange={(e)=>{ onInput(e.target.value)}}
    >
      {
        options.map(o => 
          <MenuItem value={o.value}>{o.text || o.value}</MenuItem>
        )
      }
      {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
    </Select>
  </FormControl>;


  return <select {...field.inputProps}
    // onChange={(e)=>{console.log("-select onChg")}}
    onInput={(e)=>{ onInput(e.target.value)}}
  >
      {
        options.map(o => 
        <option value={o.value} selected={o.value == data.value}>{o.text || o.value}</option>)
      }
    </select>;
});




//   (props) =>{
//   return <div>{value}</div>;
// });



function getComp({ data }: IDataGridFieldComponentProps){
  return <div>{data.value}</div>;
}

// function FieldSelect(props: IDataGridFieldComponentProps): IDataGridFieldComponent {
//   return <div>string...</div>;
// }

//type IDataGridRow = ILookup<IDataGridData>;
export interface IDataGridRow {
  values: ILookup<IDataGridData>
  expandable?: boolean
}


const optionsTp = [
  { value:"anonymousUser" , text:"Anonymous User"},
  { value:"apiTokenUser" , text:"Api Token User"},
  { value:"jwtTokenUser" , text:"Jwt Token User"},
  { value:"anyUser" , text:"Any User"},
  { value:"singleUser" , text:"Single User"},
  { value:"groupUser" , text:"Group User"},
];

const permissionFields: IDataGridField[] = [
  { fieldName:"id", fieldText: "ID"},
  { fieldName:"userId", fieldText: "User ID"},
  { fieldName:"authType", fieldText: "Authorization Type"},
  { fieldName:"crudRuleString", fieldText: "CRUD Rule"},
  { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];

const attributePermissionFields: IDataGridField[] = [
  { fieldName:"id", fieldText: "ID"},
  { fieldName:"fieldName", fieldText: "Field Name"},
  { fieldName:"userId", fieldText: "User ID"},
  { fieldName:"authType", fieldText: "Authorization Type"},
  { fieldName:"crudRuleString", fieldText: "CRUD Rule"},
  { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];



export const permissionsUpsertSchema: IUpsertFieldSchema[] = [
  { fieldName:"userId", fieldText: "User ID", required: true},
  { fieldName:"authType", fieldText: "Authorization Type", required: true, componentFn: DataGridSelectComponent, inputProps: {options: optionsTp}},
  { fieldName:"crudRuleString", fieldText: "CRUD Rule", required: true},
  { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];

const atrributePermissionsUpsertSchema: IUpsertFieldSchema[] = [
  { fieldName:"fieldName", fieldText: "Field Name", required: true},
  { fieldName:"userId", fieldText: "User ID", required: true},
  { fieldName:"authType", fieldText: "Authorization Type", required: true, componentFn: DataGridSelectComponent, inputProps: {options: optionsTp}},
  { fieldName:"crudRuleString", fieldText: "CRUD Rule", required: true},
  { fieldName:"permissionsCrudRuleString", fieldText: "Permissions CRUD Rule"},
];

function DataRowExpandToggle({ onExpanded }: any){
  const [isExpanded, setIsExpaded] = useState(false);
  
  return <ListItemButtonCustom
      //variant="contained"
      onClick={()=>{
        onExpanded(!isExpanded);
        setIsExpaded(!isExpanded);
      }}
    >
      <ListItemIconCustom>
        <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight }/>  
      </ListItemIconCustom>
      {/* {isExpanded ?  "\\/":">"} */}
    </ListItemButtonCustom>;
}


export function PermissionAttributesDataGrid({attributePermissions, permissionId}: any) {
  const [ addNewAttributePermission ] = useMutation(ADD_NEW_ATTRIBUTE_PERMISSION);
  // const [ updatePermission ] = useMutation(UPDATE_PERMISSION);
  // const [ deletePermission ] = useMutation(DELETE_PERMISSION);
  console.log("attr perm", attributePermissions, permissionId);
  //return <span>permissions attribute data grid</span>;
  // const handleDelete = async(data: any) => {
  //   if(data.id){//add new
  //     console.log("delete permission?", data);
  //     const deletePermissionResult = await deletePermission({ variables: { id: data.id} });
  //     return deletePermissionResult;
  //   }else{//update
  //     console.log("attempt to delete invalid permission", data);
  //   }
  //   //TODO: refetch...
  // };
  const handleUpsert = async(data: any) => {
    if(!data.id){//add new
      console.log("add attribute permission?", data);
      const addPermissionResult = await addNewAttributePermission({ variables: { input: data} });
      return addPermissionResult;
    }else{//update
      console.log("update attribute permission?", data);
      throw "update attribute permission not implemented";
      // const { id, userId, crudRuleString, permissionsCrudRuleString, authType } = data;
      // const updatePermissionResult = await updatePermission({ variables: { input: {id, userId, crudRuleString, permissionsCrudRuleString, authType}} });
      // return updatePermissionResult;
    }
    //TODO: refetch...
  };

  const upsertModalProps = {   
    entityName:"Attribute Permission",
    data:{
      permissionId: permissionId,
    },
    schema: atrributePermissionsUpsertSchema,
    // upsertFunction: handleUpsert,
  };

  return CrudDataGrid<any>({ 
    fields: attributePermissionFields,
    rowData: attributePermissions, 
    // appId, 
    handleUpsert, 
    // handleDelete,
    //upsertSchema: permissionsUpsertSchema,
    upsertModalProps,
  });
}

export default function PermissionsDataGrid({authEntries, appId}: any) {
  const [ addNewPermission ] = useMutation(ADD_NEW_PERMISSION);
  const [ updatePermission ] = useMutation(UPDATE_PERMISSION);
  const [ deletePermission ] = useMutation(DELETE_PERMISSION);

  const handleDelete = async(data: any) => {
    if(data.id){//add new
      console.log("delete permission?", data);
      const deletePermissionResult = await deletePermission({ variables: { id: data.id} });
      return deletePermissionResult;
    }else{//update
      console.log("attempt to delete invalid permission", data);
    }
    //TODO: refetch...
  };
  const handleUpsert = async(data: any) => {
    if(!data.id){//add new
      console.log("add permission?", data);
      const addPermissionResult = await addNewPermission({ variables: { input: data} });
      return addPermissionResult;
    }else{//update
      console.log("update permission?", data);
      const { id, userId, crudRuleString, permissionsCrudRuleString, authType } = data;
      const updatePermissionResult = await updatePermission({ variables: { input: {id, userId, crudRuleString, permissionsCrudRuleString, authType}} });
      return updatePermissionResult;
    }
    //TODO: refetch...
  };

  const getExpandedContent = (rowData: IDataGridRow) => {
    return <PermissionAttributesDataGrid attributePermissions={rowData?.attributes?.value || []} permissionId={rowData?.id?.value}/>;
  };

  const upsertModalProps = {      
    entityName:"Permission",
    data:{
      targetTypeName: "App",
      targetId: appId,
    },
    schema: permissionsUpsertSchema,
    upsertFunction: handleUpsert,
  };

  return CrudDataGrid<any>({ 
    fields: permissionFields,
    rowData: authEntries, 
    appId, 
    handleUpsert, 
    handleDelete,
    //upsertSchema: permissionsUpsertSchema,
    upsertModalProps,
    getExpandedContent,
  });
}

export interface ICrudDataGridProps<TData> {
  fields: IDataGridField[]
  rowData: IDataGridRow[]
  //appId: number
  //upsertSchema: IUpsertFieldSchema[]
  upsertModalProps?: IUpsertItemModalProps<TData>
  handleUpsert?: (newData: TData) => Promise<TData>
  handleDelete?: (newData: TData) => Promise<TData>
  getExpandedContent?: (rowData: IDataGridRow) => ReactNode //TODO: Add generic type..
}

export function CrudDataGrid<TData>({fields, rowData, handleUpsert, handleDelete, upsertModalProps, getExpandedContent }: ICrudDataGridProps<TData>) {
  //const [upsertItemOpen, setUpsertItemOpenAux] = useState(false);
  //const [_, setConfirmModalOpenAux] = useState(false);
  //const [selectedRow, setSelectedRow] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const [rowExpandContents, setRowExpandContents] = useState(rowData.map(() => <></>));

  // const columns = getHeaders([{name: "id"}, {name: "lastName"}]);
  console.log("---> rowData", rowData, fields);

  const usePermissions: IDataGridRow[] = rowData.map((p: IDataGridRow) => 
    Object.keys(p).reduce((r: IDataGridRow, k) => {
      r.values[k] = { value: p[k]};
      return r;
    }, {values:{}, expandable: true })
  );

  const setUpsertItemOpen = (isOpen) => {
    // !isOpen && setSelectedRow(null);
    !isOpen && setSelectedData({});
    //setUpsertItemOpenAux(isOpen);
  };

  const setConfirmModalOpen = (isOpen) => {
    !isOpen && setSelectedData({});
  };
    //permissionFields.map(f => ({ value: p[f.field]}))
    
    
  // const handleUpsert = async(data: any) => {
  //   if(!data.id){//add new
  //     const addPermissionResult = createNew && await createNew(data);
  //     // const addPermissionResult = await addNewPermission({ variables: { input: data} });
  //     //console.log("got addPermissionResult", addPermissionResult);
  //   }else{//update
  //     throw "update not implemented";
  //   }
  //   //TODO: refetch...
  // };

  //return <h1>Permissions?</h1>;
  //const columns = getHeaders(schema);

  //Object.keys(schema).map(k => ({
  //const fieldKeys = Object.keys(schema);

  //console.log("got modal data", selectedRow);

  const modalData = selectedData?.data && Object.keys(selectedData.data).reduce((r,k) => {
    r[k] = selectedData.data[k].value;
    return r;
  }, {});

  return (<>
    { upsertModalProps && <>
      <UpsertItemModal
        open={selectedData?.mode == "add" || selectedData?.mode == "update"}
        setOpen={setUpsertItemOpen}
        // entityName="Permission"
        // data={{
        //   targetTypeName: "App",
        //   targetId: appId,
        // }}
        // schema={upsertSchema}
        upsertFunction={handleUpsert}
        {...upsertModalProps}

        //data={modalData || upsertModalProps.data}
        data={{...upsertModalProps?.data, ...modalData}}
      />
      <ConfirmModal
        open={selectedData?.mode == "delete"}
        setOpen={setConfirmModalOpen}
        headerText={"Confirm Delete"}
        message={`Are you sure you want to delete this ${upsertModalProps?.entityName}. You will not be able to undo this action.`}
        onConfirm={async(hasConfirmed) => {
          if(hasConfirmed) await handleDelete(modalData);
          setConfirmModalOpen(false);
        }}
      />
    </>}
    <TableCustom>
      <thead>
      <tr>
        { getExpandedContent && <th></th>}
        {
          fields.map(c => <th>{c.fieldText || c.fieldName}</th>)
        }
        <th></th>
      </tr>
      </thead>
      <tbody>
      {
        usePermissions.map((p, pi) => 
          <>

            <tr>
              { getExpandedContent
                && 
                <td><DataRowExpandToggle onExpanded={(isExpanded)=> {
                  const newRwContents = [...rowExpandContents];
                  //newRwContents[pi] = !isExpanded ? <></> : <tr><td></td><td colSpan={99}>expanded row?</td></tr>;
                  newRwContents[pi] = !isExpanded ? <></> 
                    : <tr><td></td><td colSpan={99}>
                      { getExpandedContent(p.values) }
                      {/* <PermissionAttributesDataGrid attributePermissions={p.values?.attributes?.value || []} permissionId={p.values?.id?.value}/> */}
                    </td></tr>;
                  setRowExpandContents(newRwContents);
                }}/></td>
              }
              {
                fields.map(f => {
                  console.log("dgData", p.values[f.fieldName], p.values, f.fieldName);
                  const dataTp: IDataGridData =  p.values[f.fieldName];
                  console.log("dataTp", f.fieldName, dataTp);
                  return (


                    <td>{
                        (f?.componentFn && f.componentFn({
                          data: dataTp, 
                          field: f
                        })) || 
                        dataTp?.value.toString() || <i>null</i> // && p[f.field]
                      }
                    </td>
                    );
                })
              }
              <td>
                <ListItemButtonCustom
                  onClick={() => {
                    setSelectedData({
                      mode: "update",
                      data: p.values,
                    });

                    // setSelectedRow(p.values);
                  }}
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </ListItemButtonCustom>
              </td>
              <td>
                <ListItemButtonCustom
                  onClick={() => {
                    // setSelectedRow(p.values);
                    // setConfirmModalOpen(true);
                    setSelectedData({
                      mode: "delete",
                      data: p.values,
                    });

                    //setSelectedRow(p.values);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ListItemButtonCustom>
              </td>
            </tr>
            { rowExpandContents[pi] }
          </>
        )
      }
      <tr>
        <td
          colSpan={99}
          onClick={() => {
            setSelectedData({
              mode: "add",
              data: {},
            });
          }}
        >
          {/* <ListItemIconCustom> */}
            <FontAwesomeIcon icon={faPlus}/> 
          {/* </ListItemIconCustom> */}
          <span>Add New {upsertModalProps?.entityName}</span>
        </td>
      </tr>
      </tbody>
    </TableCustom>
  </>);
}
