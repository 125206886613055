import { createContext, useContext, useEffect } from "react";

const DragEventsContext = createContext({});

const DragEventsProvider = ({children}) => {
  const dragEventsContextData = {
    msDrag: null,
    msDragDrop: null,
  };

  // let msDrag;
  // let msDragDrop;

  //let contentData = { msDrag, msDragDrop }
  
  console.log("ms move define", window);

  //useEffect(()=>{
    window.addEventListener("mousemove", (e) => {
      //console.log("ms move?");
      if(dragEventsContextData.msDrag){
        dragEventsContextData.msDrag(e);
      }
    });
    window.addEventListener("mouseup", (e) => {
      if(dragEventsContextData.msDrag){
        dragEventsContextData.msDrag = null;
      }
      if(dragEventsContextData.msDragDrop){
        dragEventsContextData.msDragDrop(e);
        dragEventsContextData.msDragDrop = null;
      }
    });
    console.log("ms move, have defined", window);
  //},[]);


  // Use a Provider to pass the current theme to the tree below.
  // Any component can read it, no matter how deep it is.
  // In this example, we're passing "dark" as the current value.
  return (
    <DragEventsContext.Provider value={dragEventsContextData}>
      { children }
    </DragEventsContext.Provider>
  );
};


export function useDragEvents(){
  return useContext(DragEventsContext);
}

export default DragEventsProvider;

